import Link                       from 'next/link';
import { useTranslation }         from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useUser }                from 'hooks';
import Page                       from 'components/Page';
import SitePage                   from 'components/Site/Page';
import styles                     from './not-found.module.scss';

import ArrowBackIcon              from '@material-ui/icons/ArrowBack';

function Custom404 (props) {
  const { t } = useTranslation('common');
  const title = t('errors.404');
  const user = useUser();
  const RootComponent = user ? Page : SitePage;

  return (
    <RootComponent {...props} title={title}>
      <div className={styles.root}>
        <div className={styles.content}>
          <img src="/images/404.png" alt=""/>

          <h1>
            {t('errors.error')} 404
          </h1>

          <p>{t('errors.404')}</p>

          <Link href="/repairs?tab=all">
            <a>
              <ArrowBackIcon/> {t('common.actions.toRepairs')}
            </a>
          </Link>
        </div>
      </div>
    </RootComponent>
  )
};

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...await serverSideTranslations(locale, ['common', 'header', 'landing'])
    }
  }
}

export default Custom404;